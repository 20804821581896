
import { Component, Vue, Watch } from "vue-property-decorator";
import { marketplaceRouteMeta, MarketplaceRouteName } from "@/types/localtypes";
import { Route } from "vue-router";
import { registrationsService } from "@/services/registrations";
import {
  EnterprisePlusMultiServersCommercialRequest,
  MarketplaceRegistrationRequest,
  MPVerifyTokenResponse,
  ServerRequestModel,
  SubscriptionPaymentProvider,
  CloudProviderCode,
} from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import SvgInline from "@/components/common/SvgInline.vue";

@Component({
  name: "CloudLanding",
  components: { LoadingMask, SvgInline },
})
export default class CloudLanding extends Vue {
  routeParentChildMeta: marketplaceRouteMeta = {};
  showProceed = true;
  showNavigate = true;
  credentialsFilled = false;
  allFormsData!: MarketplaceRegistrationRequest;
  currentRouteName!: MarketplaceRouteName;
  isLoading = false;
  canSubmitForm = false;
  verifyTokenTries = 0;
  verifyTokenMaxTries = 6;
  awsAccountIdFilled = false;
  isOss = false;
  isEnterprise = false;
  isEnterprisePlus = false;
  redirectAfterVerifyToken = false;
  isVerifyingToken = false;
  showBackButton = false;
  isProceedEnabled = false;

  created() {
    this.getRouteParentChildMeta();
    this.showBackButton = this.isShowBackButton();
  }

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    this.getRouteParentChildMeta();
    this.showBackButton = this.isShowBackButton();
  }

  get proceedText() {
    if (this.isVerifyingToken) {
      return `Loading your AWS subscription`;
    }
    if (["mp.environment", "mp.ent.environment", "mp.ent.plus.environment"].includes(this.currentRouteName)) {
      return "Complete";
    }
    return "Proceed";
  }

  get slogan() {
    switch (this.routeParentChildMeta.subscriptionType) {
      case "AWS_PRO_TEAM":
        return { headline: "Start as Pro Team", description: "From AWS Marketplace" };
      case "AWS_ENTERPRISE":
        this.isEnterprise = true;
        return { headline: "Start as Enterprise", description: "From AWS Marketplace" };
      case "AWS_ENTERPRISE_PLUS":
        this.isEnterprisePlus = true;
        return { headline: "Start as Enterprise+", description: "From AWS Marketplace" };
      case "TRIAL_ENTERPRISE_PLUS":
        if (this.routeParentChildMeta.pageType === "ERROR") {
          this.showNavigate = false;
          return { headline: "", description: "" };
        }
        return { headline: `Start your <br/>Enterprise+ Trial`, description: "" };
    }
  }

  get routeParentChildMetaSteps() {
    switch (this.routeParentChildMeta.subscriptionType) {
      case "AWS_ENTERPRISE":
        return this.routeParentChildMeta.enterpriseSteps;
      case "AWS_ENTERPRISE_PLUS":
        return this.routeParentChildMeta.enterprisePlusSteps;
      case "TRIAL_ENTERPRISE_PLUS":
        return this.routeParentChildMeta.trialEnterprisePlusSteps;
      default:
        return this.routeParentChildMeta.steps;
    }
  }

  getRouteParentChildMeta() {
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });
    const step = Number(this.routeParentChildMeta.step);
    this.showProceed =
      step !== this.routeParentChildMetaSteps.length ||
      (this.routeParentChildMeta["subscriptionType"] === "TRIAL_ENTERPRISE_PLUS" && step !== 3);
    this.currentRouteName = this.routeParentChildMetaSteps[step - 1] as MarketplaceRouteName;
  }

  isActivePage(page: string) {
    const step = this.routeParentChildMeta["step"];
    if (page === step) {
      return "active";
    }
  }

  nextPage() {
    if (this.isVerifyingToken) {
      return;
    }
    const step = Number(this.routeParentChildMeta.step);
    if (step === 1) {
      sessionStorage.setItem("uiReferralLandingPage", window.location.origin + window.location.pathname);
    }
    const routeName = this.routeParentChildMetaSteps[step];
    switch (routeName) {
      case "mp.ent.subscribe":
      case "mp.ent.plus.subscribe":
        this.handleSubscribeStep(routeName);
        break;
      case "mp.waiting":
        this.submitFormData();
        break;
      case "mp.ent.waiting":
        this.submitFormDataForEnterprise();
        break;
      case "mp.ent.plus.waiting":
        this.allFormsData.isEnterprisePlus = true;
        this.submitFormDataForEnterprisePlus(false);
        break;
      case "trial.ent.plus.waiting":
        this.allFormsData.isEnterprisePlus = true;
        this.submitFormDataForEnterprisePlus(true);
        break;
      default:
        this.$router.push({ name: routeName });
    }
    this.checkIfProceedEnabled();
  }

  private handleSubscribeStep(routeName: string) {
    if (this.redirectAfterVerifyToken) {
      this.$router.push({ name: routeName });
    } else {
      this.verifyTokenForEnterprise();
    }
  }

  previousPage() {
    if (this.routeParentChildMeta.subscriptionType === "TRIAL_ENTERPRISE_PLUS") {
      this.isProceedEnabled = false;
    }
    this.$router.back();
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  setCredentialsFilled(value: boolean) {
    this.credentialsFilled = value;
    this.checkIfProceedEnabled();
  }

  checkIfProceedEnabled() {
    switch (this.currentRouteName) {
      case "mp.ent.account":
      case "mp.ent.plus.account":
        this.isProceedEnabled = this.awsAccountIdFilled;
        break;
      case "mp.subscribe":
      case "mp.ent.subscribe":
      case "mp.ent.plus.subscribe":
      case "trial.ent.plus.subscribe":
        this.isProceedEnabled = this.credentialsFilled;
        break;
      case "mp.environment":
      case "mp.ent.environment":
      case "mp.ent.plus.environment":
      case "trial.ent.plus.environment":
        this.isProceedEnabled = this.canSubmitForm;
        break;
      default:
        this.isProceedEnabled = false;
    }
  }

  onCanSubmitForm(value: boolean) {
    this.canSubmitForm = value;
    this.checkIfProceedEnabled();
  }

  isShowBackButton() {
    return [
      "mp.environment",
      "mp.ent.environment",
      "mp.ent.subscribe",
      "mp.ent.plus.environment",
      "mp.ent.plus.subscribe",
      "trial.ent.plus.environment",
    ].includes(this.currentRouteName);
  }

  onPasswordChange(password: string) {
    this.allFormsData.password = password;
    this.checkIfProceedEnabled();
  }

  setAllFormsData(data: MarketplaceRegistrationRequest) {
    this.allFormsData = data;
    this.checkIfProceedEnabled();
  }

  async handleAwsAccountIdFilled(data: MarketplaceRegistrationRequest) {
    if (!data) {
      this.awsAccountIdFilled = false;
      return;
    }
    this.awsAccountIdFilled = true;
    this.allFormsData = data;
    this.checkIfProceedEnabled();
  }

  async verifyTokenForEnterprise() {
    let response;
    this.verifyTokenTries++;
    this.isVerifyingToken = true;

    if (this.verifyTokenTries < this.verifyTokenMaxTries) {
      response = await this.verifyToken(this.allFormsData, false);
      if (response?.offer?.waitingMpEvent || response?.offer?.errorCode === "MP_EVENT_NOT_FOUND") {
        setTimeout(() => {
          this.verifyTokenForEnterprise();
        }, 10000);
      } else {
        this.handleVerifyTokenResponse(response as MPVerifyTokenResponse);
      }
    } else {
      try {
        response = await this.verifyToken(this.allFormsData, true);
      } catch (e) {
        this.$jfNotification.error({ text: "An error occurred when trying to verify the token" });
      }
      this.handleVerifyTokenResponse(response as MPVerifyTokenResponse);
    }
  }

  private async verifyToken(data: MarketplaceRegistrationRequest, forcePublicOffer: boolean) {
    try {
      const request = {
        mpBuyerId: data.awsAccountId,
        mpToken: data.awsToken,
        mpOrigin: this.mpOrigin,
        forcePublicOffer,
        onlyCheckSSO: false,
      };
      return await registrationsService.verifyMarketplaceToken(request);
    } catch (e) {
      this.$jfNotification.error({ text: "An error occurred when trying to verify the token" });
    }
  }

  handleVerifyTokenResponse(response: MPVerifyTokenResponse) {
    this.allFormsData.publicOfferWasForced = true;
    if (response.offer) {
      this.allFormsData.publicOfferWasForced = response.offer.forcedToPublicOffer;
    }
    this.redirectAfterVerifyToken = true;

    this.allFormsData.subscriptionType = response.targetPaymentType;
    sessionStorage.setItem("registrationFormData", JSON.stringify(this.allFormsData));
    this.isVerifyingToken = false;
    this.nextPage();
  }

  get mpOrigin() {
    switch (this.routeParentChildMeta.subscriptionType) {
      case "AWS_PRO_TEAM":
      case "AWS_ENTERPRISE":
      case "AWS_ENTERPRISE_PLUS":
        return "AWS_MP";
    }
    return "";
  }

  async submitFormData() {
    this.isLoading = true;
    try {
      const response = await registrationsService.marketplaceSubmit(this.allFormsData);

      this.isLoading = false;

      if (response.token) {
        const waitingRoomData = {
          token: response.token,
          serverName: this.allFormsData.serverName,
          regionName: this.allFormsData.regionName,
          instanceUrl: this.allFormsData.instanceUrl,
          cloudProvider: this.routeParentChildMeta.provider,
        };
        sessionStorage.setItem("waitingRoomData", JSON.stringify(waitingRoomData));
        await this.$router.push({ name: "mp.waiting" });
      }
    } catch (e) {
      this.isLoading = false;
      this.$jfNotification.error({ text: "An error occurred when submitting marketplace subscription" });
    }
  }

  async submitFormDataForEnterprise() {
    this.isLoading = true;
    this.allFormsData.isEnterprise = this.isEnterprise;
    this.allFormsData.isEnterprisePlus = this.isEnterprisePlus;
    this.allFormsData.isOss = this.isOss;
    this.allFormsData.mpBuyerId = this.allFormsData.awsAccountId;
    try {
      const response = await registrationsService.marketplaceSubmit(this.allFormsData);
      this.isLoading = false;

      if (response.token) {
        const waitingRoomData = {
          token: response.token,
          serverName: this.allFormsData.serverName,
          regionName: this.allFormsData.regionName,
          instanceUrl: this.allFormsData.instanceUrl,
          cloudProvider: this.routeParentChildMeta.provider,
        };
        sessionStorage.setItem("waitingRoomData", JSON.stringify(waitingRoomData));
        await this.$router.push({ name: "mp.ent.waiting" });
      }
    } catch (e) {
      this.isLoading = false;
      this.$jfNotification.error({ text: "An error occurred when submitting marketplace subscription." });
    }
  }

  async submitFormDataForEnterprisePlus(isTrial: boolean) {
    this.isLoading = true;
    this.allFormsData.mpBuyerId = this.allFormsData.awsAccountId;
    if (isTrial) {
      this.allFormsData.password = sessionStorage.getItem("pass") as string;
    }
    try {
      const servers = this.getEPlusServers();
      const paymentProvider = this.getPaymentProvider() as SubscriptionPaymentProvider;
      const request: EnterprisePlusMultiServersCommercialRequest = { ...this.allFormsData, servers, paymentProvider };
      const response = isTrial
        ? await registrationsService.trialEnterprisePlusSubmit(request)
        : await registrationsService.marketplaceEnterprisePlusSubmit(request);
      this.isLoading = false;

      if (response.token) {
        const waitingRoomData = {
          token: response.token,
          serverName: this.allFormsData.serverName,
          regionName: this.allFormsData.regionName,
          instanceUrl: this.allFormsData.instanceUrl,
          cloudProvider: this.routeParentChildMeta.provider,
          isEnterprisePlus: this.allFormsData.isEnterprisePlus,
          isTrialEnterprisePlus: isTrial,
        };

        sessionStorage.setItem("waitingRoomData", JSON.stringify(waitingRoomData));
        sessionStorage.removeItem("pass");
        await this.$router.push({ name: "mp.ent.plus.waiting" });
        this.showProceed = false;
      }
    } catch (e) {
      this.isLoading = false;
      const text =
        this.routeParentChildMeta.subscriptionType === "TRIAL_ENTERPRISE_PLUS"
          ? "An error occurred when submitting E+ subscription."
          : "An error occurred when submitting marketplace E+ subscription.";
      this.$jfNotification.error({ text });
    }
  }

  private getEPlusServers() {
    const regionCode = this.allFormsData.regionCode;
    const initialPassword = this.allFormsData.password;

    const mothership: ServerRequestModel = {
      regionCode,
      whitelistIps: [],
      initialPassword,
      products: ["artifactory", "xray", "distribution", "pipelines"],
      serverName: this.allFormsData.serverName,
      isMothership: true,
    };
    return [mothership];
  }

  getPaymentProvider(): CloudProviderCode {
    return "AWS";
  }
}
